import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export async function saveToPdf(elem) {
  console.log(elem);
  const opt = {
    margin: 1,
    filename: "report.pdf",
    image: { type: "jpeg", quality: 1 },
    html2canvas: { scale: 2 },
    enableLinks: true,
    pageBreak: {
      /* mode: ["css", "legacy"], */
      after: ".page-break",
    },
    jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
  };

  html2pdf().set(opt).from(elem).save();
}

export async function saveMultipleToPdf(refs, header, onStart, onEnd) {
  console.log("GENERATING PDF");
  onStart();
  let canvasArr;

  //DEFER BLOCKING SYNC OPERATION TO NOT BLOCK THE DOM UPDATE
  // CONVERT CARDS TO CANVAS ELEMENTS
  await new Promise((resolve) => {
    setTimeout(async () => {
      canvasArr = await Promise.all(
        refs.map(async (ref) => await html2canvas(ref))
      );
      resolve();
    }, 0);
  });

  // CONVERT HEADER TO CANVAS ELEMENT
  const canvasHeader = await html2canvas(header);
  console.log("RECEIVED ELEMENTS:", canvasArr, canvasHeader);

  //@@ SIZE AND POSITIONING
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const ratio = windowWidth / windowHeight;
  const headerWidth = 300;
  const headerHeight = (canvasHeader.height * headerWidth) / canvasHeader.width;
  const imgWidth = 90;
  const internalMargin = 10;

  //@@ PAGINATION
  const pages = Math.ceil(refs.length / 3);
  // "l" landscape orientation
  const doc = new jsPDF("l", "mm");
  const docSize = doc.internal.pageSize;

  //@@WRITE CANVAS TO PDF DOCUMENT
  for (let i = 0; i < canvasArr.length; i += 3) {
    console.log("ITERATING:", i, canvasArr.length);

    //SET CUSTOM MARGINS FOR 1 or 2  and 3 GRAPHS SCENARIO TO CENTER THE GRAPHS IN THE PAGE
    const marginLeft =
      (docSize.width -
        (imgWidth + internalMargin) *
          (canvasArr[i + 2] ? 3 : canvasArr[i + 1] ? 2 : 1)) /
      2;

    //KEEP IMG RATIO
    const imgHeight = (canvasArr[i].height * imgWidth) / canvasArr[i].width;
    const img1 = canvasArr[i].toDataURL("image/jpeg", 0.8);
    const img2 = canvasArr[i + 1]
      ? canvasArr[i + 1].toDataURL("image/jpeg", 0.8)
      : null;
    const img3 = canvasArr[i + 2]
      ? canvasArr[i + 2].toDataURL("image/jpeg", 0.8)
      : null;
    const imgKeys = ["img1", "img2", "img3"];
    const imgs = {
      img1,
      img2,
      img3,
    };
    const settings = {
      img1: {
        position: {
          x: marginLeft,
          y: 35,
        },
        label: "graph1",
      },
      img2: {
        position: {
          x: marginLeft + imgWidth + internalMargin,
          y: 35,
        },
        label: "graph2",
      },
      img3: {
        position: {
          x: marginLeft + 2 * (imgWidth + internalMargin),
          y: 35,
        },
        label: "graph3",
      },
    };

    const headerData = canvasHeader.toDataURL("image/jpeg", 0.7);

    doc.addImage(headerData, "PNG", 0, 0, headerWidth, headerHeight, "header");
    imgKeys.forEach((key) => {
      if (imgs[key])
        doc.addImage(
          imgs[key],
          "PNG",
          settings[key].position.x,
          settings[key].position.y,
          imgWidth,
          imgHeight,
          imgs[key].label
        );
    });
    doc.setFontSize(8);
    doc.text(
      docSize.width / 2 - 8,
      docSize.height - 3,
      `Page ${Math.floor(i / 3) + 1} of ${pages}`
    );
    //Do not add a white page the last iteraation
    if (i + 3 < canvasArr.length) doc.addPage();
  }
  const res = await doc.save("report.pdf", { returnPromise: true });
  console.log("PRINTED!", res);
  onEnd();
}
