import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import { Spin, Row, Col, Statistic, Table } from 'antd';
import { Chart, Axis, Tooltip, Geom, PieChart, Legend, Line, Coordinate } from 'bizcharts';
import moment from "moment";

const numberFormatter = item => new Intl.NumberFormat('en-US').format(item);
const colors = ["#7DB3FF", "#49457B", "#FF7C78"];

let search = window.location.search;
let params = new URLSearchParams(search);
  

let startDate = params.get('start');
let endDate = params.get('end');

let start = moment(startDate, "YYYY-MM-DD");
let end = moment(endDate, "YYYY-MM-DD");

//Difference in number of days
const days = moment.duration(end.diff(start)).asDays();
console.log(days);
localStorage.setItem('days', JSON.stringify(days));


function sumProperty(arr, type) {

  return arr.reduce((total, obj) => {
    
    if (typeof obj[type] === 'string') {
      return total + Number(obj[type]);
    }

    return total + obj[type];
  }, 0);
}

const stackedBarChartData = (resultSet) => {
  const data = resultSet.pivot().map(
    ({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ', '),
        color: resultSet.axisValuesString([yValues[0]], ', '),
        [yValues[1]]: m && Number.parseFloat(m)
      }))
  ).reduce((a, b) => a.concat(b));

  return data;
}


const stackedChartData = (resultSet) => {
  
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ', '),
        color: resultSet.axisValuesString(yValues, ', '),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};
const stackedChartDataHorizontal = (resultSet) => {
  
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ', '),
        color: resultSet.axisValuesString(yValues, ', '),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data.reverse();
};

const TypeToChartComponent = {
  line: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 8,
          },
        }}
        autoFit
        height={400}
        data={stackedChartData(resultSet)}
        forceFit
      >
        <Axis name="x" />
        <Axis name="measure" />
        <Tooltip
          crosshairs={{
            type: 'y',
          }}
        />
        <Geom type="line" position="x*measure" size={2} color="color" />
      </Chart>
    );
  },
  bar: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 10,
          },
        }}
        autoFit
        height={400}
        data={stackedChartData(resultSet)}
        forceFit
      >
        <Axis
         name="x"
         label = {{
            //rotate:  30,   
            autoRotate: true,
            Offset: 30
          }}
        />
        <Axis name="measure"  label= {{ formatter: val => `${numberFormatter(val)}` }}/>
        <Legend selectedmode="multiple" visible={false} />
        <Tooltip />
        <Geom type="interval" position="x*measure" color="color" />
      </Chart>
    );
  },
  barStacked: ({ resultSet, colors }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 10,
          },
        }}
        autoFit
        height={400}
        data={stackedChartData(resultSet)}
        forceFit
      >
        <Axis
         name="x"
         label = {{
            //rotate:  30,   
            autoRotate: true,
            Offset: 30
          }}
        />
        <Axis name="measure"  label= {{ formatter: val => `${numberFormatter(val)}` }} />
        <Legend selectedmode="multiple" visible={false} />
        <Tooltip />
        <Geom type="interval" position="x*measure" position={`x*measure`}
          color={["color", colors]} />
          
      </Chart>
    );
  },
  barHorizontal: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 10,
          },
        }}
        autoFit
        height={400}
        data={stackedChartDataHorizontal(resultSet)}
        forceFit
      >
        <Axis
         name="x"
         label = {{
            //rotate:  30,   
            autoRotate: true,
            Offset: 30
          }}

        />
        <Axis name="measure" label= {{ formatter: val => `${numberFormatter(val)}` }}  />
        <Legend selectedmode="multiple" visible={false} />
        <Tooltip />
        <Coordinate reflect="measure" />
        <Coordinate transpose />
        <Geom type="interval" position="x*measure" color="color" />
      </Chart>
    );
  },
  
  area: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 8,
          },
        }}
        autoFit
        height={400}
        data={stackedChartData(resultSet)}
        forceFit
      >
        <Axis name="x" />
        <Axis name="measure" />
        <Tooltip
          crosshairs={{
            type: 'y',
          }}
        />
        <Geom type="area" position="x*measure" size={2} color="color" />
      </Chart>
    );
  },
  pie: ({ resultSet }) => {
    return (
      <PieChart
        data={resultSet.chartPivot()}
        radius={0.6}
        angleField={resultSet.series()[0].key}
        colorField="x"
        label={{
          visible: true,
          offset: 15,
          formatter: val => `${numberFormatter(val[resultSet.series()[0].key])}`,
        }}
        legend={{
          position: 'bottom',
          labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 10
                    }
                }
        }}
        forceFit
      />
    );
  },
  piePercentage: ({ resultSet }) => {
    return (
    
      <PieChart
        data={resultSet.chartPivot()}
        radius={0.6}
        angleField={resultSet.series()[0].key}
        colorField="x"
        label={{
          visible: true,
          offset: 20,
          formatter: val => `${(val[resultSet.series()[0].key]/(sumProperty(resultSet.chartPivot(), resultSet.series()[0].key) )*100).toFixed(2)}%`,
        }}
        legend={{
          position: 'bottom',
        }}
        forceFit
      /> 
    );
  },
  pieSide3: ({ resultSet }) => {
    return (
      <Row>
        <Col span={18}>
          <PieChart height={400}
            data={resultSet.chartPivot()}
            radius={0.8}
            angleField={resultSet.series()[0].key}
            colorField="x"
            label={{
              visible: true,
              offset: 20,
            }}
            legend={{
              position: 'top',
            }}
          />
        </Col>
        <Col span={6}>
          <h3>Side</h3>
        </Col>
      </Row>
    );
  },
  number: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            
           <Statistic style={{
          fontWeight: 'bold',
        }} value={resultSet.totalRow()[s.key]} />
            
            
          ))}
        </Col>
      </Row>
    );
  },

  numberYear: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>     
                  <span className="increment ant-btn ant-btn-round ant-btn-sm" style={{marginTop: '-10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? "red" : "green"}} >
                  {~~Number(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key]))/~~Number(resultSet.loadResponse.results[1].data[0][s.key])*100)))}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span>
                  <Statistic style={{
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }} value={resultSet.loadResponse.results[0].data[0][s.key]} />
                 
                   
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  numberYearDay: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              <div>     
                  <span  className="increment ant-btn ant-btn-round ant-btn-sm" style={{marginTop: '-10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? "red" : "green"}} >
                  {Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span>
                  <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={resultSet.loadResponse.results[0].data[0][s.key]} />
                    <small style={{float:'left', paddingLeft:'5px',paddingTop:'10px', fontSize: '14px'}}>{(resultSet.loadResponse.results[0].data[0][s.key]/365).toFixed(2)}/day</small>
                  </div>
              </div>
            ))}
        </Col>
      </Row>
    );
  },
  numberDay: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
           <Statistic style={{
          fontWeight: 'bold',
        }} value={(resultSet.loadResponse.results[0].data[0][s.key]/localStorage.getItem('days')).toFixed(2)} />
          ))}
        </Col>
      </Row>
    );
  },
  numberPrevYear: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>     
                  <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={resultSet.loadResponse.results[0].data[0][s.key]} />
                   {resultSet.loadResponse.results[1].data[0][s.key] &&  <small style={{float:'left', paddingLeft:'5px',paddingTop:'5px', fontSize: '18px'}}>/ {resultSet.loadResponse.results[1].data[0][s.key].toLocaleString('en-US')}</small> }
                  </div>
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  table: ({ resultSet, pivotConfig }) => {
    return (
      <Table
        pagination={false}
        columns={resultSet.tableColumns(pivotConfig)}
        dataSource={resultSet.tablePivot(pivotConfig)}
      />
    );
  },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = (Component) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} />) ||
  (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState, title }) => {
  const { query, chartType } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return component && renderChart(component)(renderProps);
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null,
};
export default ChartRenderer;
