import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import App from "./App";
ReactDOM.render(
  <Router>
    <App>
      <Switch>
        <Route key="index" exact path="/">
          <DashboardPage />
        </Route>
      </Switch>
    </App>
  </Router>, // eslint-disable-next-line no-undef
  document.getElementById("root")
);
