// eslint-disable-next-line
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  Spin,
  Space,
  DatePicker,
  Checkbox,
  Tooltip,
} from "antd";
import ChartRenderer from "../components/ChartRenderer";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import { useLocation } from "react-router-dom";
import { PrinterOutlined, CloseCircleOutlined } from "@ant-design/icons";

import useDashboardItems from "./hooks/useDashboardItems";
import { saveMultipleToPdf } from "../utils";
const { RangePicker } = DatePicker;

const datetime = (new Date()).toISOString().slice(0, 19).replace("T", " ");


const DashboardPage = () => {
  const [elements, setElements] = useState([]);
  const [areSettingsOpen, setAreSettingsOpen] = useState(false);
  const [canStartConversion, setCanStartConversion] = useState(false);
  const [isConverting, setIsConverting] = useState(false);

  const location = useLocation();
  let { onDateChange, dashboardItems, startDate, endDate } = useDashboardItems(
    location.search
  );
  const headerRef = useRef(null);

  const onConversionStart = useCallback(() => {
    setIsConverting(true);
  }, []);

  const onConversionEnd = useCallback(() => {
    setIsConverting(false);
  }, []);

  //DEFER SYNC THREAD PDF CONVERSION TO THE DOM UPDATE

  useEffect(() => {
    if (canStartConversion) {
      saveMultipleToPdf(
        elements,
        headerRef.current,
        onConversionStart,
        onConversionEnd
      );
      setCanStartConversion(false);
      setElements([]);
    }
  }, [canStartConversion]);

  console.log("ELEMS", elements);
  return location.search ? (
    <div>
      <Dashboard>
        <Col
          ref={headerRef}
          span={24}
          lg={24}
          key={1}
          style={{
            marginBottom: "32px",
          }}
        >
          <h1
            style={{
              fontSize: "40px",
            }}
          >
            Executive Dashboard
          </h1>
          <h3
            style={{
              fontSize: "20px",
            }}
          >
            From {startDate} to {endDate}
          </h3>

          <div
            style={{ position: "absolute", width: "80px", top: "30px", right: "30px" }}
          >
            <img src="/logo.png" style={{ width: "80px" }} />
          </div>

          {!isConverting && (
            <Space direction="vertical" size={12}>
              <RangePicker onChange={onDateChange} />
            </Space>
          )}
          <div style={{ float: "right" }}>
            {areSettingsOpen && (
              <Button
                type="primary"
                size="large"
                icon={<PrinterOutlined />}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  //PLACE SCROLL TO 0 TO PREVENT HEADER CUT
                  window.scrollTo(0, 0);
                  setAreSettingsOpen(false);
                  setCanStartConversion(true);
                }}
              >
                PRINT
              </Button>
            )}
            {!isConverting ? (
              !areSettingsOpen ? (
                <Button
                  onClick={() => {
                    setAreSettingsOpen(true);
                  }}
                  type="secondary"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Settings
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setAreSettingsOpen(false);
                    setElements([]);
                  }}
                  type="secondary"
                  size="large"
                  icon={<CloseCircleOutlined />}
                >
                  Close Settings
                </Button>
              )
            ) : null}
          </div>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={2}
          style={{
            marginBottom: "32px",
          }}
        >
          {isConverting && (
            <div className="conversion-spinner">
              <Spin size="large" />
            </div>
          )}
          <DashboardItem title="OVERALL OFFLINE UNITS">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <ChartRenderer vizState={dashboardItems[1].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Units Seized
            </h3>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Region
            </h3>
            <ChartRenderer vizState={dashboardItems[0].vizState} />
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={3}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="OVERALL OFFLINE ACTIONS">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <ChartRenderer vizState={dashboardItems[3].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Actions Completed
            </h3>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Region
            </h3>
            <ChartRenderer vizState={dashboardItems[2].vizState} />
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          sm={12}
          key={4}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="OVERALL OFFLINE IMPACT">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <ChartRenderer vizState={dashboardItems[5].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Impact (USD)
            </h3>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Region
            </h3>
            <ChartRenderer vizState={dashboardItems[4].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="OVERALL UNITS (%)" style={{ height: "550px" }}>
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Region
            </h3>
            <ChartRenderer vizState={dashboardItems[6].vizState} />
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="OVERALL ACTIONS (%)">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Region
            </h3>
            <ChartRenderer vizState={dashboardItems[7].vizState} />
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="OFFICERS & COUNTRIES TRAINED"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <ChartRenderer vizState={dashboardItems[9].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Officers Trained
            </h3>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Country
            </h3>
            <ChartRenderer vizState={dashboardItems[8].vizState} />
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="DAILY OFFLINE ACTIVITY">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "120px" }}></div>
            <ChartRenderer vizState={dashboardItems[10].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Units Seized Daily
            </h3>
            <ChartRenderer vizState={dashboardItems[11].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Actions Daily
            </h3>
            <ChartRenderer vizState={dashboardItems[12].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Impact Daily (USD)
            </h3>
            <div style={{ paddingBottom: "120px" }}></div>
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="OVERALL ONLINE OPERATIONS">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "120px" }}></div>
            <ChartRenderer vizState={dashboardItems[13].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Total Domains & Websites
            </h3>
            <ChartRenderer vizState={dashboardItems[14].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Auctions Marketplace
            </h3>
            <ChartRenderer vizState={dashboardItems[15].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Absolute impact (USD)
            </h3>
            <div style={{ paddingBottom: "120px" }}></div>
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="DAILY ONLINE OPERATIONS">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "120px" }}></div>
            <ChartRenderer vizState={dashboardItems[16].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Domains closed daily
            </h3>
            <ChartRenderer vizState={dashboardItems[17].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Auctions closed daily
            </h3>
            <ChartRenderer vizState={dashboardItems[18].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Impact daily (USD)
            </h3>
            <div style={{ paddingBottom: "120px" }}></div>
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="OFFLINE Y2Y PERFORMANCE">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "120px" }}></div>
            <ChartRenderer vizState={dashboardItems[19].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Units Seized Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[20].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Actions Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[21].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Impact Y2Y (USD)
            </h3>
            <div style={{ paddingBottom: "60px" }}></div>
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="ONLINE Y2Y PERFORMANCE">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "120px" }}></div>
            <ChartRenderer vizState={dashboardItems[22].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Domains & Websites Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[23].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Actions Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[24].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Impact Y2Y
            </h3>
            <div style={{ paddingBottom: "60px" }}></div>
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="ACTIVE COUNTRIES">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Region
            </h3>
            <ChartRenderer vizState={dashboardItems[25].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 10 COUNTRIES UNITS"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[26].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 10 COUNTRIES ACTIONS"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[27].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 10 COUNTRIES IMPACT"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[28].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="ACTIONS BREAKDOWN - UNITS"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[29].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="ACTIONS BREAKDOWN - ACTIONS"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[30].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="ACTIONS BREAKDOWN - IMPACT"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[31].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 PRODUCTS - UNITS"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[33].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 PRODUCTS - ACTIONS"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[32].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 PRODUCTS - IMPACT"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[34].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="FOCUS FOOTWEAR Y2Y">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "120px" }}></div>
            <ChartRenderer vizState={dashboardItems[35].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Units Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[36].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Actions Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[37].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Impact (USD) Y2Y
            </h3>
            <div style={{ paddingBottom: "120px" }}></div>
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="FOCUS FACE MASKS Y2Y">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "120px" }}></div>
            <ChartRenderer vizState={dashboardItems[38].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Units Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[39].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Actions Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[40].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Impact (USD) Y2Y
            </h3>
            <div style={{ paddingBottom: "120px" }}></div>
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="FOCUS FACTORY RAIDS Y2Y">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "120px" }}></div>
            <ChartRenderer vizState={dashboardItems[49].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Units Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[50].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Actions Y2Y
            </h3>
            <ChartRenderer vizState={dashboardItems[51].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Impact (USD) Y2Y
            </h3>
            <div style={{ paddingBottom: "120px" }}></div>
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 COUNTRIES - AMERICAS (Units)"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[41].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 COUNTRIES - APAC (Units)"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[43].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 COUNTRIES - EMEA (Units)"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[45].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 COUNTRIES - AMERICAS (Actions)"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[42].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 COUNTRIES - APAC (Actions)"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[44].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={5}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem
            title="TOP 5 COUNTRIES - EMEA (Actions)"
            style={{ height: "550px" }}
          >
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <div style={{ paddingBottom: "96px" }}></div>
            <ChartRenderer vizState={dashboardItems[46].vizState} />
          </DashboardItem>
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="CATEGORY SHOES">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            
            <ChartRenderer vizState={dashboardItems[52].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Units Seized
            </h3>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Region
            </h3>
            <ChartRenderer vizState={dashboardItems[47].vizState} />
          </DashboardItem>

          
        </Col>

        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
          <DashboardItem title="CATEGORY FACE MASKS">
            {areSettingsOpen && (
              <Tooltip placement="top" title="Select to print">
                <input
                  className="pdf-check-input"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  type="checkbox"
                  onChange={(e) => {
                    const nodeClone = e.target?.parentNode;
                    if (e.target.checked) {
                      setElements((arr) => [...arr, nodeClone]);
                    } else {
                      setElements((arr) => {
                        const idx = arr.indexOf(nodeClone);
                        console.log(idx);
                        return [
                          ...arr.slice(0, idx),
                          ...arr.slice(idx + 1, arr.length),
                        ];
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            <ChartRenderer vizState={dashboardItems[53].vizState} />
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              Units Seized
            </h3>
            <h3
              className="subtitle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-30px",
                marginTop: "20px",
              }}
            >
              by Region
            </h3>
            <ChartRenderer vizState={dashboardItems[48].vizState} />
          </DashboardItem>
        </Col>
        <Col
          span={8}
          lg={8}
          sm={12}
          xs={24}
          key={6}
          style={{
            marginBottom: "32px",
          }}
        >
        <DashboardItem title="Executive Dashboard">
        <img src="/logo.png" style={{ width: "80px", marginTop: "100px", marginLeft: "170px" }} />
        <h3 style={{marginTop: '100px', textAlign: 'center'}}>Generated on {datetime}</h3>
        <h3 style={{marginTop: '0px', textAlign: 'center'}}>For any question contact cmp@uabp.eu</h3>
        </DashboardItem>
        </Col>
      </Dashboard>
    </div>
  ) : (
    <Dashboard>
      <Col
        span={24}
        lg={24}
        key={1}
        style={{
          marginBottom: "32px",
        }}
      >
        <h1
          style={{
            fontSize: "40px",
          }}
        >
          Executive Dashboard
        </h1>
        <h3>
          From {startDate} to {endDate}
        </h3>

        <Space direction="vertical" size={12}>
          <RangePicker onChange={onDateChange} />
        </Space>
      </Col>
    </Dashboard>
  );
};

export default DashboardPage;
