import { useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function useDashboardItems(search) {
  const history = useHistory();

  const defaultFormat = "YYYY-MM-DD";
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  console.log("SEARCH", search);
  let params = new URLSearchParams(search);
  let startDate = params.get("start");
  let endDate = params.get("end");
  let prevStart = moment(startDate).subtract(1, "years").format(defaultFormat);
  let prevEnd = moment(endDate).subtract(1, "years").format(defaultFormat);
  if (!startDate) {
    let start = moment().startOf("year");
    let end = moment();
    startDate = start.format(defaultFormat);
    endDate = end.format(defaultFormat);
    prevStart = start.subtract(1, "years").format(defaultFormat);
    prevEnd = end.subtract(1, "years").format(defaultFormat);
  }
  console.log(startDate, endDate, prevStart, prevEnd);

  const onDateChange = (newDate) => {
    console.log("CHANGING DATE", newDate);
    startDate = newDate[0].format(defaultFormat);
    endDate = newDate[1].format(defaultFormat);
    history.push({
      pathname: "/",
      search: `?start=${startDate}&end=${endDate}`,
    });
  };

  const dashboardItems = [
    {
      id: 0,
      name: "OVERALL OFFLINE OPERATIONS",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {
            "Units.amount": "desc",
          },
          dimensions: ["Regions.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.value",
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        chartType: "pie",
      },
    },
    {
      id: 1,
      name: "Units",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.value",
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        chartType: "numberYear",
      },
    },
    {
      id: 2,
      name: "Actions Completed",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {
            "Actions.amount": "desc",
          },
          dimensions: ["Regions.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
            
          ],
        },
        chartType: "pie",
      },
    },

    {
      id: 3,
      name: "Actions",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
          ]
        },
        chartType: "numberYear",
      },
    },
    {
      id: 4,
      name: "Impact",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {
            "Totals.amount": "desc",
          },
          dimensions: ["Regions.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Totals.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Regions.value",
              operator: "in",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["1", "2", "7"],
            },
          ],
        },
        chartType: "pie",
      },
    },
    {
      id: 5,
      name: "Impact",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Totals.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Regions.value",
              operator: "in",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
            
          ],
        },
        chartType: "numberYear",
      },
    },
    {
      id: 6,
      name: "OVERALL UNITS (%)",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {
            "Units.amount": "desc",
          },
          dimensions: ["Regions.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "in",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
            {
              dimension: "Units.value",
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        chartType: "piePercentage",
      },
    },
    {
      id: 7,
      name: "OVERALL ACTIONS (%)",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {
            "Actions.amount": "desc",
          },
          dimensions: ["Regions.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "in",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["1", "2", "7"],
            },
          ],
        },
        chartType: "piePercentage",
      },
    },
    {
      id: 8,
      name: "Attendees",
      vizState: {
        query: {
          measures: ["Attendees.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              dateRange: [startDate, endDate],
            },
          ],
          order: {},
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["2. Trainings"],
            },
          ],
        },
        chartType: "pie",
      },
    },
    {
      id: 9,
      name: "Attendees",
      vizState: {
        query: {
          measures: ["Attendees.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
              //dateRange: [startDate,endDate],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["2. Trainings"],
            },
          ],
        },
        chartType: "numberYearDay",
      },
    },
    {
      id: 10,
      name: "OVERALL OFFLINE OPERATIONS",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {},
          //dimensions: ['Regions.value'],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "in",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
          ],
        },
        chartType: "numberDay",
      },
    },
    {
      id: 11,
      name: "Actions Completed",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {},
          //dimensions: ['Regions.value'],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["1", "2", "7"],
            },
            {
              dimension: "Regions.value",
              operator: "in",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
          ],
        },
        chartType: "numberDay",
      },
    },
    {
      id: 12,
      name: "Impact",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {},
          //dimensions: ['Regions.value'],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["1", "2", "7"],
            },
            {
              dimension: "Regions.value",
              operator: "in",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
          ],
        },
        chartType: "numberDay",
      },
    },
    {
      id: 13,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Countries.value",
              operator: "contains",
              values: ["Domain", "Website"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["5"],
            },
          ],
        },
        chartType: "number",
      },
    },
    {
      id: 14,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {
            "Units.amount": "desc",
          },
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Countries.value",
              operator: "contains",
              values: ["Market Place App & Social Media"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["5"],
            },
          ],
        },
        chartType: "number",
      },
    },
    {
      id: 15,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {
            "Totals.amount": "desc",
          },
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Countries.value",
              operator: "contains",
              values: ["Market Place App & Social Media", "Domain", "Website"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["5"],
            },
          ],
        },
        chartType: "number",
      },
    },
    {
      id: 16,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Countries.value",
              operator: "contains",
              values: ["Domain", "Website"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["5"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        chartType: "numberDay",
      },
    },
    {
      id: 17,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Countries.value",
              operator: "contains",
              values: ["Market Place App & Social Media"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["5"],
            },
          ],
        },
        chartType: "numberDay",
      },
    },
    {
      id: 18,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Countries.value",
              operator: "contains",
              values: ["Market Place App & Social Media", "Domain", "Website"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["5"],
            },
          ],
        },
        chartType: "numberDay",
      },
    },
    {
      id: 19,
      name: "Units",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.value",
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 20,
      name: "Actions Completed",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 21,
      name: "Impact",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 22,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
         
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Countries.value",
              operator: "contains",
              values: ["Domain", "Website"],
            },
            
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 23,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Countries.value",
              operator: "equals",
              values: ["Market Place App & Social Media"],
            },
            {
              dimension: "Issues.status",
              operator: "equals",
              values: ["5"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 24,
      name: "Online issues with domains",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["4. Online"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["Internet"],
            },
            {
              dimension: "Countries.value",
              operator: "contains",
              values: ["Market Place App & Social Media","Domain","Website"],
            },
            {
              dimension: "Issues.status",
              operator: "equals",
              values: ["5"],
            },
            
          ],
        },
        chartType: "numberPrevYear",
      },
    },

    {
      id: 25,
      name: "ACTIVE COUNTRIES",
      vizState: {
        query: {
          measures: ["CountriesGrouped.count"],
          //ungrouped: false,
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
            },
          ],
          order: {
            "CountriesGrouped.count": "desc",
          },
          dimensions: ["Regions.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["EMEA","APAC","Americas"],
            },
          ],
        },
        chartType: "pie",
      },
    },

    {
      id: 26,
      name: "TOP 10 COUNTRIES Units",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Units.amount": "desc" },
          limit: 10,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },

    {
      id: 27,
      name: "TOP 10 COUNTRIES Actions",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Actions.amount": "desc" },
          limit: 10,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Issues.count",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["1", "2", "7"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 28,
      name: "TOP 10 COUNTRIES Impact",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Totals.amount": "desc" },
          limit: 10,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 29,
      name: "UNITS PLACE OF INTEREST",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Units.amount": "desc" },
          //limit: 10,
          dimensions: ["Places.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
          ],
        },
        chartType: "bar",
      },
    },
    {
      id: 30,
      name: "UNITS PLACE OF INTEREST",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Actions.amount": "desc" },
          //limit: 10,
          dimensions: ["Places.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Issues.count",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        chartType: "bar",
      },
    },
    {
      id: 31,
      name: "UNITS PLACE OF INTEREST",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Totals.amount": "desc" },
          //limit: 10,
          dimensions: ["Places.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Totals.amount",
              operator: "gt",
              values: ["0"],
            },
          ],
        },
        chartType: "bar",
      },
    },
    {
      id: 32,
      name: "TOP 5 PRODUCTS - UNITS",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Actions.amount": "desc" },
          limit: 5,
          dimensions: ["Products.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Products.value",
              operator: "set",
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Issues.status",
              operator: "contains",
              values: ["1", "2", "7"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 33,
      name: "TOP 5 PRODUCTS - ACTIONS",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Units.amount": "desc" },
          limit: 5,
          dimensions: ["Products.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Products.value",
              operator: "set",
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 34,
      name: "TOP 5 PRODUCTS - IMPACT",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Totals.amount": "desc" },
          limit: 5,
          dimensions: ["Products.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Products.value",
              operator: "set",
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 35,
      name: "FOCUS FOOTWEAR",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {
            "Units.amount": "desc",
          },
          dimensions: [],
          filters: [
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["shoes"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 36,
      name: "FOCUS FOOTWEAR",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {
            "Actions.amount": "desc",
          },
          dimensions: [],
          filters: [
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["shoes"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 37,
      name: "FOCUS FOOTWEAR",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {
            "Totals.amount": "desc",
          },
          dimensions: [],
          filters: [
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["shoes"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },

    {
      id: 38,
      name: "FOCUS FACEMASK",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          dimensions: [],
          filters: [
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["Face Masks"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 39,
      name: "FOCUS FACEMASK",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          dimensions: [],
          filters: [
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["Face Masks"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 40,
      name: "FOCUS FACEMASK",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          dimensions: [],
          filters: [
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["Face Masks"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },

    {
      id: 41,
      name: "TOP 5 COUNTRIES AMERICAS",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Units.amount": "desc" },
          limit: 5,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "equals",
              values: ["AMERICAS"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 42,
      name: "TOP 5 COUNTRIES AMERICAS",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Actions.amount": "desc" },
          limit: 5,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "equals",
              values: ["AMERICAS"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 43,
      name: "TOP 5 COUNTRIES AMERICAS",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Units.amount": "desc" },
          limit: 5,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "equals",
              values: ["APAC"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 42,
      name: "TOP 5 COUNTRIES AMERICAS",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Actions.amount": "desc" },
          limit: 5,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "equals",
              values: ["APAC"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 45,
      name: "TOP 5 COUNTRIES EMEA",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Units.amount": "desc" },
          limit: 5,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "equals",
              values: ["EMEA"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },
    {
      id: 42,
      name: "TOP 5 COUNTRIES AMERICAS",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Actions.amount": "desc" },
          limit: 5,
          dimensions: ["Countries.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Regions.value",
              operator: "equals",
              values: ["EMEA"],
            },
          ],
        },
        chartType: "barHorizontal",
      },
    },

    {
      id: 47,
      name: "TOP 5 COUNTRIES AMERICAS SHOES",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Units.amount": "desc" },
          //limit: 10,
          dimensions: ["Regions.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["Shoes"],
            },
          ],
        },
        chartType: "pie",
      },
    },

    {
      id: 48,
      name: "TOP 5 COUNTRIES AMERICAS SHOES",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              dateRange: [startDate, endDate],
              //compareDateRange: [[startDate,endDate],[prevStart,prevEnd]],
              //granularity: 'year'
            },
          ],
          order: { "Units.amount": "desc" },
          //limit: 10,
          dimensions: ["Regions.value"],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Regions.value",
              operator: "contains",
              values: ["AMERICAS", "APAC", "EMEA"],
            },
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["Face Masks"],
            },
          ],
        },
        chartType: "pie",
      },
    },

    {
      id: 49,
      name: "FOCUS RAID",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {
            "Units.amount": "desc",
          },
          dimensions: [],
          filters: [
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Places.value",
              operator: "contains",
              values: ["(F)actory"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 50,
      name: "FOCUS RAID",
      vizState: {
        query: {
          measures: ["Actions.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {
            "Actions.amount": "desc",
          },
          dimensions: [],
          filters: [
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Places.value",
              operator: "contains",
              values: ["(F)actory"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Actions.value",
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 51,
      name: "FOCUS RAID",
      vizState: {
        query: {
          measures: ["Totals.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {
            "Totals.amount": "desc",
          },
          dimensions: [],
          filters: [
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Places.value",
              operator: "contains",
              values: ["(F)actory"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
          ],
        },
        chartType: "numberPrevYear",
      },
    },
    {
      id: 52,
      name: "Units",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["Shoes"],
            }
          ],
        },
        chartType: "numberYear",
      },
    },
    {
      id: 53,
      name: "Units",
      vizState: {
        query: {
          measures: ["Units.amount"],
          timeDimensions: [
            {
              dimension: "Issues.startDate",
              compareDateRange: [
                [startDate, endDate],
                [prevStart, prevEnd],
              ],
            },
          ],
          order: {},
          dimensions: [],
          filters: [
            {
              dimension: "Projects.identifier",
              operator: "equals",
              values: ["case-management"],
            },
            {
              dimension: "Trackers.name",
              operator: "equals",
              values: ["1. Cases"],
            },
            {
              dimension: "Units.value",
              operator: "gte",
              values: ["1"],
            },
            {
              dimension: "Units.amount",
              operator: "gt",
              values: ["0"],
            },
            {
              dimension: "Products.value",
              operator: "equals",
              values: ["Face Masks"],
            },
          ],
        },
        chartType: "numberYear",
      },
    }
  ];

  return {
    startDate,
    endDate,
    prevStart,
    prevEnd,
    defaultFormat,
    onDateChange,
    dashboardItems,
  };
}
